<template>
    <div class="index_unplanned index_all" v-loading="loading">
        <div class="year_select">
            <!-- <el-date-picker size="mini" v-model="year" type="year" value-format="yyyy" :clearable="false"
                            class="year_select_input" @change="getOffProjectNumber"></el-date-picker> -->
        </div>
        <div class="total_title" style="padding-left: 42px;">
            {{ year }}年度计划外项目共{{ project_num }}个，金额及物资累计折算约{{ sum_money }}万元
        </div>
        <div class="chart_static">
            <div class="chart_item chart_100px">
                <div class="info">
                    <div class="title">项目数量统计</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart1" :key="index">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}个</div>
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart1"></div>
            </div>
            <div class="chart_item chart_100px">
                <div class="info">
                    <div class="title">金额及物资累计折算</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart2" :key="index">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}万元</div>
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart2"></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import chart from '@/mixins/chart.js'

export default {
    props:{
        year:{
            type:String,
        }
    },
    mixins: [ chart],
    data() {
        return {
            project_num: 0, sum_money: 0,
            chart1: [
                {value: 0, name: '州本级', color: '#f7a35c'},
                {value: 0, name: '玛沁县', color: '#8085e9'},
                {value: 0, name: '甘德县', color: '#7cb5ec'},
                {value: 0, name: '达日县', color: '#434348'},
                {value: 0, name: '班玛县', color: '#e4d354'},
                {value: 0, name: '久治县', color: '#f15c80'},
                {value: 0, name: '玛多县', color: '#90ed7d'}
            ],
            chart2: [
                {value: 0, name: '州本级', color: '#f7a35c'},
                {value: 0, name: '玛沁县', color: '#8085e9'},
                {value: 0, name: '甘德县', color: '#7cb5ec'},
                {value: 0, name: '达日县', color: '#434348'},
                {value: 0, name: '班玛县', color: '#e4d354'},
                {value: 0, name: '久治县', color: '#f15c80'},
                {value: 0, name: '玛多县', color: '#90ed7d'}
            ],
        }
    },
    watch: {
        year() {
            this.getOffProjectNumber()
        }
    },
    created() {
        this.getOffProjectNumber();
    },
    methods: {
        getOffProjectNumber() {
            this.loading = true;
            api.home.getOffProjectNumber({year: this.year}).then(res => {
                if (res.data) {
                    if (res.data.rows) {
                        let rows = res.data.rows;
                        if (rows.project_num) this.project_num = rows.project_num;
                        if (rows.sum_money) this.sum_money = Number((Number(rows.sum_money) / 10000).toFixed(2));
                        if (rows.data) {
                            rows.data.forEach(item => {
                                this.chart1.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.num;
                                    }
                                })
                            })
                            rows.data.forEach(item => {
                                this.chart2.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = Number((Number(item.money) / 10000).toFixed(2));
                                    }
                                })
                            })
                        }
                    }
                }
                this.loading = false;
                this.setChart('chart1');
                this.setChart('chart2', {
                    trigger: 'item',
                    formatter: "{b}<br/>金额及物资累计折算 : {c}万元<br/>总占比 : {d}%"
                });
            })
        }
    }
}
</script>

<style scoped lang="less">
@import "../all/all.less";
@import "../index_global.less";
</style>